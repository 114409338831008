.contentBlockedTime {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 30px;
  align-items: start;
}
.calendar {
}
.blockedTime {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.actions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
.actions > .button {
  padding: 12px 16px;
}
.blockedTimeSheet {
  background-color: var(--color-background-secondary);
  border: 1px solid var(--color-input-border);
  padding: 20px;
  border-radius: 14px;
}
.blockedTimeList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  color: var(--color-text-primary);
  font-weight: 500;
  font-size: 14px;
  max-height: 400px;
  overflow: auto;
}
.blockedTimeItemWrapper {
  position: relative;
  padding-left: 20%;
}
.blockedTimeItemWrapper:after {
  content: '';
  position: absolute;
  left: 10%;
  top: 50%;
  display: block;
  width: 15px;
  height: 2px;
  background-color: var(--color-accent);
  transform: translate(0, -50%);
}
.blockedTimeItem {
  position: relative;
  background-color: var(--color-background-primary);
  border-radius: 6px;
  padding: 10px 10px 10px 20px;
  overflow: hidden;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
}
.blockedTimeItem:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 5px;
  background-color: var(--color-accent);
}

@media (max-width: 768px) {
  .contentBlockedTime {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .blockedTime {
    width: 100%;
  }
}
