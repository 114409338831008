.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
.formHeader {
  max-width: 170px;
}
.formWeek {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
}
.formTime {
  display: grid;
  grid-template-columns: repeat(2, max-content 230px);
  align-items: center;
  grid-gap: 20px;
}
.titleTimeStart {
  font-weight: 500;
  color: var(--color-text-secondary);
}
.titleTimeEnd {
  color: var(--color-text-secondary);
  font-weight: 700;
}
.titleAppointment {
  padding: 20px 0;
}
.description {
  font-size: 14px;
  font-weight: 500;
}
.wrapperAppointmentInput {
  max-width: 230px;
}

.actions {
  padding: 30px 0 50px;
  max-width: 830px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 992px) {
  .formWeek {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

@media (max-width: 768px) {
  .formTime {
    grid-template-columns: max-content 1fr;
    grid-gap: 10px 20px;
  }
}

@media (max-width: 576px) {
  .formTime {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
  .wrapperAppointmentInput {
    max-width: 100%;
  }
  .actions {
    grid-template-columns: 1fr;
  }
}
