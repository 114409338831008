.labelCheckbox {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  align-items: center;
  font-size: inherit;
  padding: 15px 0 15px 40px;
  cursor: pointer;
}
.checkboxInput {
  appearance: none;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: polygon(0, 0, 0, 0);
}
.checkboxInput:focus {
  outline: none;
}
.checkboxInput:focus + .box {
  box-shadow: var(--box-shadow-primary);
}
.box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid var(--color-text-primary);
  overflow: hidden;
  background-color: var(--color-background-primary);
}
.checkboxInput:checked + .box {
  background-color: var(--color-text-primary);
}

.message {
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
}
.message.error {
  color: var(--color-primary);
}
.message.description {
}
