.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}
.header {
  padding-top: 50px;
}
.title {
  font-weight: 600;
  font-size: 44px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 60px;
  margin-bottom: 50px;
  align-items: end;
}
.actions .addButton {
  width: 282px;
  padding: 12px 16px;
}

.content {
  background-color: var(--color-background-secondary);
  min-height: 350px;
}
.content .container {
  padding-top: 50px;
  padding-bottom: 100px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
}

.listLabels {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 32px;
}
.listLabels .label {
  font-size: 14px;
  font-weight: 500;
}
.invoicesList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding-bottom: 50px;
}

.notFoundBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundBlockTitle {
  margin-bottom: 20px;
}
.notFoundBlock .button {
  width: 200px;
}
.patientsPaginationWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 992px) {
  .patientActions {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .listLabels {
    display: none;
  }
  .invoicesList {
    grid-gap: 0;
  }
}

@media (max-width: 768px) {
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  .actions .addButton {
    width: 100%;
  }
  .patientListItem {
    padding: 16px;
    grid-template-columns: max-content 1fr;
    grid-gap: 16px;
  }
  .patientActions {
    grid-area: 2/1/4/4;
  }
}

@media (max-width: 576px) {
  .content {
    margin: 0 -16px;
  }
  .content .container {
    padding-bottom: 50px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 24px;
  }
  .patientList {
    padding-bottom: 30px;
  }
}
