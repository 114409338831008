.search {
}
.searchHeader {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 16px;
}
.searchLabel {
  font-weight: 500;
}
.searchToggleButtons {
  background-color: var(--color-background-secondary);
  border-radius: 100px;
}
.searchToggleButtons .searchToggleButton {
  padding: 3px 25px;
  font-size: 14px;
}
.searchInputWrapper {
  position: relative;
}
.searchIconStart {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 26px;
  color: var(--color-text-secondary);
}
.searchIconEnd {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 26px;
  color: var(--color-text-primary);
  cursor: pointer;
}
.searchInput {
  border-radius: 100px;
  border: 1px solid var(--color-input-border);
  padding: 13px 46px;
  font-size: 16px;
  line-height: 28px;
  width: 100%;
  color: var(--color-text-primary);
}
.searchInput:focus {
  outline: none;
  border: 1px solid var(--color-input-border-focus);
}
.searchInput::placeholder {
  color: var(--color-text-secondary);
}
.actionIcon {
  cursor: pointer;
}

@media (max-width: 576px) {
  .searchHeader {
    grid-template-columns: max-content;
    grid-gap: 10px;
  }
}
