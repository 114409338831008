.wrapper {
  min-height: 171px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.formGrid {
  display: grid;
  grid-template-columns: minmax(175px, 0.87fr) 100px 80px 80px 1fr 1fr 140px;
  grid-gap: 5px;
  align-items: center;
}
.actions {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.actions .button {
  width: 100%;
}
.categoryWrapper {
  position: relative;
}
.categoryWrapper .button {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 992px) {
  .formGrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
