.containerSetting {
  max-width: 725px;
  margin: 0;
}
.formGrid {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 16px;
}
.formSubGrid {
  display: grid;
  grid-template-columns: repeat(2, 146px);
  grid-gap: 16px;
}

.formSettingGrid {
  padding: 25px;
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  position: relative;
}
.formSettingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.formSettingTitle {
  font-size: 20px;
}
.formSettingEditButton {
  display: inline-block;
  width: 44px;
  height: 44px;
}

@media (max-width: 576px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .formSubGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .formSettingGrid {
    padding: 25px 25px 40px;
    margin: 0 -25px;
    border-radius: 0px;
  }
  .formSettingEditButton {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-50%, 50%);
  }
}
