.formGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
}
.lineGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.lineSubGrid {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: start;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 30px;
}
.lineGridSecond {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
  display: inline-block;
}
.fileWrapper {
  padding-top: 30px;
}

.actions {
  padding: 0 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 768px) {
  .lineGrid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
  .formGrid {
    grid-gap: 30px;
  }
  .lineGridSecond {
    grid-template-columns: 1fr;
  }
  .subtitle {
    font-size: 24px;
  }
  .actions {
    grid-template-columns: 1fr;
  }
}
