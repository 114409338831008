.wrapper {
  position: relative;
  grid-area: 1/2/2/2;
}
.planner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 20px;
}
.plannerHeader {
  display: flex;
  justify-content: flex-end;
}
.buttonClose {
  cursor: pointer;
}
.calendar {
  display: flex;
  justify-content: center;
  padding: 24px 40px;
  background-color: var(--color-background-secondary);
  border-radius: 14px;
}
.dayPlanner {
  background-color: var(--color-background-secondary);
  padding: 24px 0 24px 24px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 500;
}
.dayPlannerHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 33px;
  padding-right: 24px;
}
.dayPlannerTitle {
  font-size: 20px;
}
.dayPlannerLink {
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
  font-size: 16px;
}
.dayPlannerLink:hover {
  opacity: 0.7;
}
.dayPlannerTimeList {
  position: relative;
  padding-top: 20px;
  padding-right: 24px;
  overflow: auto;
  max-height: 812px;
  color: var(--color-text-secondary);
}
.dayPlannerTimeItem {
  height: 120px;
  position: relative;
}
.dayPlannerTime {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}
.dayPlannerTimeItem::before,
.dayPlannerTimeItem::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: calc(100% - 50px);
  height: 1px;
  background-color: var(--color-text-secondary);
  transform: translateY(-50%);
  opacity: 0.5;
}
.dayPlannerTimeItem::before {
  top: 50%;
  right: auto;
  left: 0;
  width: 8px;
  opacity: 1;
}

.widget {
  background-color: var(--color-background-secondary);
  padding: 24px;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widgetDate {
  display: flex;
  align-items: center;
}
.widgetDateDay {
  background-color: var(--color-date-picker-background-day);
  color: var(--color-text-accent);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
}
.widgetDateOther {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.widgetDateMonth {
  font-weight: 600;
  font-size: 20px;
}
.widgetDateYear {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-secondary);
}
.widgetActions {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.widgetActionsLabel {
  font-weight: 600;
  font-size: 20px;
}
.widgetActionsLabelMobile {
  display: none;
}

.blockedTimeItemWrapper {
  position: absolute;
  width: calc(100% - 50px);
  padding-right: 24px;
  top: 20px;
  left: 50px;
}
.blockedTimeItem {
  position: relative;
  background-color: var(--color-background-primary);
  color: var(--color-text-primary);
  border-radius: 6px;
  padding: 3px 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 5px;
  white-space: nowrap;
}
.blockedTimeItem:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 5px;
  background-color: var(--color-text-primary);
}
.blockedTimeLabel {
  color: var(--color-text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialogPlannerWrapper {
  margin: 0 -24px;
}

@media (max-width: 1200px) {
  .wrapper {
    grid-area: inherit;
  }
  .planner {
    background-color: var(--color-background-secondary);
    padding: 24px;
    border-radius: 14px;
  }
  .calendar {
    padding: 0;
  }
  .dayPlanner {
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }
  .dayPlannerTimeList {
    max-height: 885px;
  }
}

@media (max-width: 576px) {
  .widget {
    align-items: flex-start;
  }
  .widgetDate {
    align-items: flex-start;
  }
  .widgetDateDay {
    margin-top: 7px;
  }
  .widgetDateOther {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: end;
    grid-gap: 5px;
  }
  .widgetDateMonth {
    font-size: 14px;
  }
  .widgetActionsLabel {
    display: none;
  }
  .widgetActionsLabelMobile {
    display: block;
    font-size: 18px;
    font-weight: 600;
  }
}
