.container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.subtitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 38px;
}

@media (max-width: 576px) {
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 30px;
  }
  .subtitle {
    font-size: 18px;
    margin-bottom: 18px;
  }
}
