.container {
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-primary);
  border-radius: 10%;
  overflow: hidden;
}
.container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.container.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container > .placeholder {
  width: 80%;
  height: 80%;
}

.modal {
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: auto;
  padding: 10px;
}
.paper {
  width: 100%;
  max-width: 526px;
  position: relative;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--color-background-primary);
  border-radius: 13px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  grid-gap: 20px;
}
.paper:focus {
  outline: none;
}
.WrapperImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: var(--color-background-primary);
  box-shadow: var(--box-shadow-primary);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canDownload {
  cursor: pointer;
}
.wrapperDownload {
  text-align: center;
}
.download {
}
