.wrapper {
  height: 100%;
  background-color: var(--color-text-primary);
}
.gradientVerticalMirageWhite {
  background: #ffffff;
  background: -moz-linear-gradient(top, #1d2239 0%, #1d2239 60%, #ffffff 60%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #1d2239 0%, #1d2239 60%, #ffffff 60%, #ffffff 100%);
  background: linear-gradient(to bottom, #1d2239 0%, #1d2239 60%, #ffffff 60%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2239', endColorstr='#ffffff',GradientType=0 );
}
.bgImgWrapper {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
  height: 100%;
  z-index: 0;
}
.bgImg {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 1000px;
  min-width: 700px;
  width: 100%;
  z-index: -1;
}
.bgImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logo {
  max-width: 266px;
  margin: 0 auto 60px;
}

@media (max-width: 992px) {
  .bgImgWrapper {
    padding: 60px 0;
  }
}
@media (max-width: 768px) {
  .bgImgWrapper {
    padding: 40px 0;
  }
  .bgImg {
    top: 0;
    left: 0;
  }
  .logo {
    margin: 0 auto 30px;
  }
}
@media (max-width: 576px) {
  .bgImgWrapper {
    padding: 0;
  }
  .bgImg {
    display: none;
  }
  .logo {
    display: none;
  }
}
