.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.filesGrid {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
  grid-gap: 10px;
}
.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.actions .button {
  width: 100%;
}

@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
