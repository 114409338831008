.formGridUp {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.gridLine {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.gridArea {
  grid-area: 2/2/3/4;
}

@media (max-width: 992px) {
  .gridLine {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridArea {
    grid-area: inherit;
  }
}
@media (max-width: 768px) {
  .formGridUp {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
  .gridLine {
    grid-template-columns: 1fr;
  }
}
