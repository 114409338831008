.wrapper {
  padding: 24px 34px 24px 32px;
  border-radius: 14px;
  background-color: var(--color-background-primary);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  transition: box-shadow 0.2s;
  cursor: pointer;
}
.wrapper:hover {
  box-shadow: var(--box-shadow-primary);
}
.gridLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gridLine:first-child {
  margin-bottom: 10px;
}
.gridLine:last-child {
  padding-top: 10px;
}
.title {
  font-weight: 600;
}
.number {
  font-weight: 500;
  color: var(--color-text-secondary);
}
.subtitle {
  font-weight: 500;
  font-size: 14px;
}
.date {
  font-size: 14px;
  color: var(--color-text-secondary);
}
.status {
  color: var(--color-text-accent);
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 100px;
  display: inline-block;
  line-height: 1;
}
.status.pending {
  background-color: var(--color-secondary);
}
.status.paid {
  background-color: var(--color-accept);
}
.total {
  font-weight: 600;
}
