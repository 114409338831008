.formList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.formItem {
}
.formItemBody {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.actions {
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}
.actions > .button {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 992px) {
  .formGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 576px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .actions {
    grid-template-columns: 1fr;
  }
}
