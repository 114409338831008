div {
  /* border: 1px solid black;*/
}

#ChatWindow {
  border: 1px solid #eee;
  border-radius: 8px;
  background: #fff;
}

#SelectedConversation {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#participants {
  font-family: Monaco;
  size: 12pt;
}

#participants,
#messages {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  height: 100%;
  overflow: scroll;
  padding-bottom: 10px;
}

#OpenChannel {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.nav-pills .nav-item {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

#messagesContainer {
  flex-basis: 100%;
  flex-grow: 2;
  flex-shrink: 1;
  overflow-y: scroll;
  background-color: #f7f7f9;
}

#messagesContainer::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

#messages::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

#type-a-message {
  font-family: 'Poppins';
  font-size: 16px;
  display: inline-block;
  border: none;
  flex-basis: 100%;
  padding-top: 10px;

  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}
