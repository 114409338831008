.Dropzone {
  width: 90%;
  margin: auto;
  height: 5em;
  background-color: #fff;
  border: 2px dashed grey;
  border-radius: 2em;
  opacity: 0.3;
  visibility: visible;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 5em;
}

.drop {
  width: 100%;
  height: 100%;
  background: rgba(46, 141, 238, 0.48);
  z-index: 130;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.active:after {
  width: 100%;
  height: 100%;
  background: red;
}

.messages {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.Dropzone p {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.Highlight {
  border: 5px dashed green;
}
