.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}
.header {
  padding-top: 50px;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 60px;
  margin-bottom: 50px;
  align-items: end;
}
.actions .addButton {
  width: 282px;
  padding: 12px 16px;
}
.patientCurrent {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
}
.patientInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.patientStatus {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-secondary);
}

.content {
  background-color: var(--color-background-secondary);
  min-height: 350px;
}
.content .container {
  padding-top: 50px;
  padding-bottom: 100px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
}

.prescriptionsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding-bottom: 50px;
}

.notFoundBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundBlockTitle {
  margin-bottom: 20px;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  .actions .addButton {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .content .container {
    padding-bottom: 50px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 24px;
  }
  .prescriptionList {
    padding-bottom: 30px;
  }
  .dateCreate {
    grid-area: 3/1/3/4;
  }
  .description {
    -webkit-line-clamp: 2;
  }
}
