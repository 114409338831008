@font-face {
  font-family: 'icomoon';
  src: url('./assets/fontIcons/icomoon.eot?pb7h0k');
  src: url('./assets/fontIcons/icomoon.eot?pb7h0k#iefix') format('embedded-opentype'),
    url('./assets/fontIcons/icomoon.ttf?pb7h0k') format('truetype'),
    url('./assets/fontIcons/icomoon.woff?pb7h0k') format('woff'),
    url('./assets/fontIcons/icomoon.svg?pb7h0k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: '\e900';
}
.icon-facebook:before {
  content: '\ea90';
}
.icon-instagram:before {
  content: '\ea92';
}
.icon-youtube:before {
  content: '\ea9d';
}
