.wrapper {
  padding: 50px 0;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 70px;
}
.header {
  border-bottom: 2px solid var(--color-background-secondary);
  padding: 0 0 30px;
}
.title {
  font-size: 44px;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
}
.subheader {
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  grid-gap: 50px;
  align-items: end;
}
.info {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 20px;
  align-items: end;
}
.number {
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  color: var(--color-input-placeholder);
}
.status {
  color: var(--color-text-accent);
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 100px;
  display: inline-block;
  line-height: 1;
}
.status.pending {
  background-color: var(--color-secondary);
}
.status.paid {
  background-color: var(--color-accept);
}
.dateWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.dateLabel {
  font-weight: 600;
}
.dateValue {
  color: var(--color-text-secondary);
}

/* members */
.members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 150px;
}
.member {
}
.memberInfo {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.memberGrid {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  grid-gap: 20px;
}
.memberGrid:first-child {
  padding-bottom: 20px;
}
.memberGrid:first-child > .value {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-secondary);
  text-transform: capitalize;
}
.name {
  font-weight: 500;
}
.value {
  color: var(--color-text-secondary);
}

.services {
}
.gridServices {
  display: grid;
  grid-template-columns: 1fr repeat(4, 120px);
  grid-gap: 30px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.gridServices > span {
  font-weight: 600;
  font-size: 20px;
}
.servicesWrapper {
  border-top: 2px solid #f7f7f9;
  border-bottom: 2px solid #f7f7f9;
  padding: 22px 0 0;
}
.service {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.serviceLabel {
  font-weight: 600;
  display: none;
}
.serviceValue {
  font-weight: 500;
  color: var(--color-text-secondary);
}
.serviceSubtotal {
  color: var(--color-text-primary);
}
.form {
  max-width: 300px;
}
.wrapperTotal {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.total {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, max-content));
  grid-gap: 57px;
  font-weight: 600;
  align-items: end;
}
.labelTotal {
  font-size: 20px;
}
.counterTotal {
  font-size: 32px;
}
.actions {
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 35px;
}
.actions .button {
  width: 100%;
  padding: 14px;
}
.actions.actionsGrid {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

@media (max-width: 992px) {
  .members {
    grid-template-columns: 1fr;
    grid-gap: 70px;
  }
  .gridServices {
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 2px solid #f7f7f9;
  }
  .gridServices > .service:first-child {
    grid-area: 1/1/2/3;
  }
  .servicesWrapper {
    border-bottom: none;
  }
  .serviceLabel {
    display: inline;
  }
  .serviceValue {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .actions.actionsGrid,
  .gridServices {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 32px 0;
    grid-gap: 40px;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .subheader {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: end;
  }
  .info {
    grid-area: 1/1/2/3;
  }
  .members {
    grid-gap: 40px;
  }
  .memberGrid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .memberGrid:first-child {
    padding-bottom: 10px;
  }
  .gridServices {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
  .gridServices > .service:first-child {
    grid-area: auto;
  }
  .service {
    display: flex;
    justify-content: space-between;
  }
  .serviceLabel {
    padding-right: 10px;
  }
  .serviceValue {
    text-align: right;
  }
  .form {
    max-width: 100%;
  }
  .total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 0 20px;
  }
  .actions,
  .actions.actionsGrid {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .actions .button {
    max-width: 100%;
  }
}
