:root {
  --font-family-primary: 'Poppins', sans-serif;
  --font-family-secondary: Arial, Helvetica, sans-serif;
  --color-accept: #13bd7d;
  --color-primary: #d13b2a;
  --color-secondary: #2a64d1;
  --color-accent: #1b2a4a;
  --color-text-primary: #1d2239;
  --color-text-secondary: #8e909c;
  --color-text-accent: #ffffff;
  --color-background-primary: #ffffff;
  --color-background-secondary: #f7f7f9;
  --color-border-primary: #f7f7f9;
  --color-input-border: #edeef0;
  --color-input-border-focus: #1b2a4a;
  --color-input-placeholder: #bbbcc3;
  --color-tab-background-active: #1b2a4a;
  --color-tab-background-primary: #f7f7f9;
  --color-tab-background-secondary: #ffffff;
  --color-background-drop-zone-hover: lightyellow;
  --color-border-drop-zone: #bbbcc3;
  --color-border-table-medical-reports: #f0f0f0;
  --color-date-picker-background-day: #1b2a4a;
  --color-date-picker-background-day-keyboard-selected: #bbbcc3;
  --color-overlay-added-image-desktop: rgba(27, 42, 74, 0.5);
  --color-overlay-added-image-mobile: #1b2a4a;
  --color-advice-text: #939499;
  --color-search-primary: #c1c7d0;
  --color-secondary-opacity: rgba(42, 100, 209, 0.1);
  --box-shadow-primary: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  color: var(--color-text-primary);
  font: 400 16px/1.2 var(--font-family-primary);
  background: var(--color-background-primary);

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-primary);
}

input,
textarea {
  font-family: var(--font-family-secondary);
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

body[style*='padding-right: 15px'] .header {
  padding-right: 15px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: var(--color-date-picker-background-day);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: var(--color-date-picker-background-day-keyboard-selected);
  color: var(--color-text-primary);
}
.react-datepicker__day,
.react-datepicker__day:hover {
  border-radius: 50%;
}
.react-datepicker__day:focus {
  outline: none;
}
.react-datepicker__day.haveBlockedTime {
  position: relative;
}
.react-datepicker__day.haveBlockedTime::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

.react-datepicker__navigation {
  outline: none;
}
.react-datepicker__day--outside-month {
  color: var(--color-text-secondary);
}

.rc-time-picker-panel {
  width: 300px;
  z-index: 1400;
}
.rc-time-picker-panel-inner {
  width: 100%;
  border-radius: 8px;
  font-size: inherit;
}
.rc-time-picker-panel-input {
  font-size: inherit;
  padding: 6px;
  color: inherit;
}
.rc-time-picker-panel-narrow {
  max-width: 100%;
}
.rc-time-picker-panel-combobox {
  display: flex;
}
.rc-time-picker-panel-select {
  width: 100%;
}
.rc-time-picker-panel-select li {
  font-size: 1.5em;
  padding: 16px;
  height: auto;
}
