.wrapper {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-gap: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.title {
  font-weight: 600;
  font-size: 20px;
}
.message {
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-primary);
  border-radius: 14px;
  padding: 16px;
  align-self: end;
}
.messageHeader {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 10px;
}
.messageSender {
  font-weight: 600;
}
.messageDate {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-secondary);
}
.messageText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 576px) {
  .wrapper {
    border-radius: 0;
    padding: 16px;
  }
}
