.nav {
  align-self: center;
}
.list {
  display: flex;
  align-items: center;
}
.linkItem + .linkItem {
  margin-left: 10px;
}
.button {
  padding: 3px 15px !important;
}
.link {
  font-weight: 500;
}
