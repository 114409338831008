.wrapper {
  display: flex;
  align-items: center;
}
.user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 16px;
  line-height: 1;
}
.userName {
  font-weight: 600;
  text-transform: capitalize;
}
.link {
  font-size: 14px;
  color: var(--color-text-secondary);
}
.arrow {
  position: relative;
}
.arrow {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.arrow::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 5px;
  background-color: var(--color-input-placeholder);
  display: block;
  width: 10px;
  height: 6px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.menuItemStyle.menuItem {
  border-radius: 8px;
  overflow: hidden;
}
.menuItemStyle.menuItem.menuItemMark {
  color: var(--color-primary);
}

@media (max-width: 576px) {
  .user {
    display: none;
  }
}
