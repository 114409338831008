.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-gap: 14px;
  align-items: center;
  min-width: 350px;
}
.label {
  font-weight: 500;
}
.action {
  background: #ffffff;
  border: 1px solid #eaebf0;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 14px;
  padding: 19px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  position: relative;
}
.action:focus {
  outline: none;
  border: 1px solid var(--color-input-border-focus);
}
.actionIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(0);
  transition: transform 5s;
}
.actionIcon.open {
  transform: translateY(-50%) rotate(180deg);
}

.wrapperMenu {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  padding: 20px 30px;
  min-width: 270px;
}

.wrapperRadio {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.buttonRadio {
  font-size: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.inputRadio {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
}
.inputRadio:focus + .boxRadio {
  box-shadow: var(--box-shadow-primary);
}
.inputRadio:checked + .boxRadio {
  border: 4px solid var(--color-accent);
}
.boxRadio {
  width: 14px;
  height: 14px;
  border: 1px solid var(--color-accent);
  border-radius: 50%;
  position: absolute;
  bottom: 8%;
  left: 0;
}

.menuItemStyle.menuItem {
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 -15px;
}
.menuItemStyle.menuItem.menuItemMark {
  background-color: var(--color-background-secondary);
}

.applyAction {
  width: 100%;
  padding-top: 10px;
}
.applyAction .applyButton {
  width: 100%;
  padding: 2px 16px;
}

@media (max-width: 576px) {
  .wrapper {
    grid-template-columns: max-content 1fr;
    min-width: 50px;
  }
  .label {
    display: none;
  }
}
