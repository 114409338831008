.tabsList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 4px;
  justify-items: center;
  align-items: center;
  color: var(--color-text-secondary);
  background-color: var(--color-tab-background-primary);
  padding: 4px;
  border-radius: 100px;
}
.accentBg {
  background-color: var(--color-tab-background-secondary);
}
.tabItem {
  padding: 10px 25px;
  border-radius: 100px;
  width: 100%;
  text-align: center;
}
.active {
  background-color: var(--color-tab-background-active);
  color: var(--color-text-accent);
}

@media (max-width: 992px) {
  .tabsList {
    grid-template-columns: 1fr;
  }
  .tabItem:not(.active) {
    display: none;
  }
}
