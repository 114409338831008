.invoicesListItem {
  padding: 16px 20px;
  border-radius: 14px;
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-input-border);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  position: relative;
  min-height: 76px;
}
.invoicesListItem .invoiceInfo {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}
.invoiceInfo .label {
  display: none;
  background-color: var(--color-background-secondary);
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-primary);
  justify-content: start;
  align-items: center;
  text-align: left;
  padding-left: 16px;
}
.invoicesListItem .invoiceInfo:not(:first-child) {
  text-align: center;
}
.invoicesListItem .invoiceInfo:not(:last-of-type)::after {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -10px;
  background-color: var(--color-input-border);
  transform: translateX(-50%);
}

.valueDate {
  color: var(--color-text-secondary);
}
.valueData {
  font-weight: 500;
}
.valueStatus {
  font-weight: 500;
  text-transform: capitalize;
}
.valueStatus.pending {
  color: var(--color-secondary);
}
.valueStatus.paid {
  color: var(--color-accept);
}

.menuButton {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menuItemStyle.menuItem {
  border-radius: 8px;
  overflow: hidden;
}
.menuItemStyle.menuItem.menuItemMark {
  color: var(--color-primary);
}
.menuItemStyle.menuItem.menuItemMarkAccent {
  color: var(--color-accept);
}

@media (max-width: 992px) {
  .invoicesListItem {
    grid-template-columns: 1fr;
    padding: 0;
    border-radius: 0;
    border-top: 2px solid var(--color-accent);
    grid-gap: 0;
  }
  .invoicesListItem .invoiceInfo {
    grid-template-columns: 178px 1fr;
    height: 64px;
    align-items: stretch;
  }
  .invoiceInfo .label {
    display: flex;
  }
  .invoiceInfo .label + .nameValue,
  .invoiceInfo .label + span {
    display: flex;
    align-items: center;
    padding-left: 17px;
  }
  .invoiceInfo .label + .nameValue {
    padding-right: 25px;
  }
  .invoicesListItem .invoiceInfo:not(:last-of-type)::after {
    top: 100%;
    right: auto;
    left: 0;
    width: 100%;
    height: 2px;
    transform: translateX(0);
  }
  .menuButton {
    top: 18px;
    transform: translateY(0);
  }
}

@media (max-width: 576px) {
  .invoicesListItem .invoiceInfo {
    grid-template-columns: 78px 1fr;
  }
  .invoiceInfo .label {
    padding-left: 12px;
    padding-right: 4px;
  }
}
