.container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.wrapper {
  padding: 50px 0;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
}
.form {
}
.wrapperPatient {
  margin-bottom: 50px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 50px;
}
.gridMain {
  display: grid;
  grid-template-columns: minmax(200px, 400px) 1fr;
  grid-gap: 30px;
  margin-bottom: 50px;
}
.subGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 185px));
  grid-gap: 30px;
}
.subGrid:not(:first-child) {
  justify-self: end;
}
.gridServices {
  display: grid;
  grid-template-columns: 1fr repeat(4, 120px);
  grid-gap: 30px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.descriptionWrapper {
  position: relative;
}
.descriptionWrapper .buttonRemoveService {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 50px;
  width: 50px;
  min-width: 50px;
  padding: 0;
  margin: 1px;
  border-radius: 8px;
}
.gridServices > span {
  font-weight: 600;
  font-size: 20px;
}
.servicesWrapper {
  border-top: 2px solid #f7f7f9;
  border-bottom: 2px solid #f7f7f9;
  padding: 22px 0;
}
.servicesActions {
  display: grid;
  grid-template-columns: repeat(2, 230px);
  grid-gap: 20px;
}
.servicesActions > .button {
  padding: 12px 16px;
  width: 100%;
}
.subtotal {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subtotal > span {
  padding: 15px 0;
}
.wrapperTotal {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 40px 0 20px;
}
.total {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, max-content));
  grid-gap: 57px;
  font-weight: 600;
  align-items: end;
}
.labelTotal {
  font-size: 20px;
}
.counterTotal {
  font-size: 32px;
}

.actions {
  padding: 30px 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 992px) {
  .gridMain {
    grid-template-columns: 1fr;
  }
  .subGrid {
    grid-template-columns: 1fr 1fr;
  }
  .subGrid:not(:first-child) {
    justify-self: auto;
  }
  .gridServices {
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 2px solid #f7f7f9;
  }
  .gridServices > div:first-child {
    grid-area: 1/1/2/3;
  }
}
@media (max-width: 768px) {
  .servicesActions {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapper {
    padding: 0;
  }
  .title {
    font-size: 34px;
    margin-bottom: 30px;
  }
  .subtitle {
    font-size: 22px;
    margin-bottom: 22px;
  }
  .grid,
  .subGrid,
  .actions,
  .servicesActions {
    grid-template-columns: 1fr;
  }
  .actions {
    padding-top: 0;
  }
  .gridServices {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridServices > div:nth-child(2) {
    grid-area: 2/1/3/3;
  }
  .gridServices > div:last-child {
    grid-area: 4/1/5/3;
  }
  .subtotal {
    flex-direction: row;
  }
  .subtotal > span {
    padding: 0;
  }
  .wrapperTotal {
    padding: 20px 0;
  }
  .total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 0 20px;
  }
}
