.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-overlay-added-image-mobile);
  cursor: pointer;
  transition: box-shadow 0.2s, background-color 0.2s;
}
.editButton:hover {
  box-shadow: var(--box-shadow-primary);
  background-color: var(--color-primary);
}
