.container {
  height: 100%;
}
.grid {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 31px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
}
.doctor {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}
.doctorInfo {
  display: grid;
  grid-template-columns: 1fr 39%;
  grid-gap: 36px;
  margin-bottom: 50px;
}
.doctorPersonalData {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 24px;
}
.doctorPersonalData > div:first-child {
  grid-area: 1/1/3/2;
}
.doctorPersonalGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-gap: 5px;
}
.doctorName {
  font-size: 20px;
  text-transform: capitalize;
}
.doctorSpecialties {
}
.doctorSpecialty {
  color: var(--color-text-secondary);
}
.doctorSpecialty + .doctorSpecialty {
  padding-left: 10px;
}
.doctorPatients {
  padding: 12px 20px;
  background: #f0f0f2;
  border-radius: 15px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 10px;
  align-items: center;
  font-weight: 500;
}
.doctorPatients .doctorPatientsCount {
  padding: 2px 20px;
}

.pageWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr;
}
.pageTitle {
  font-size: 32px;
  margin-bottom: 28px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 60px;
  margin-bottom: 40px;
  align-items: end;
}
.actions .addButton {
  width: 282px;
  padding: 14px 16px;
}

/* appointments (Tabs) */
.appointments {
  background-color: var(--color-background-secondary);
  padding: 24px;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr max-content;
}
.appointmentsTabs {
  background-color: var(--color-background-primary);
  border-radius: 200px;
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 28px;
}
.appointmentsTabs .appointmentsTabsButton {
  text-transform: capitalize;
}
.sorting {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
}
.sortingMobile {
  display: none;
}
.sortingBy {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.sortingBy .sortingIcon {
  color: var(--color-text-primary);
  font-size: 16px;
}
.tabsContainer {
  min-height: 500px;
}
.appointmentsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  align-items: start;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 992px) {
  .doctorInfo {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .actions {
    grid-template-columns: 1fr;
  }
  .actions .addButton {
    width: 100%;
  }
  .appointmentsTabs {
    overflow: auto;
  }
  .appointmentsTabsButton {
    width: 250px;
  }
  .sorting {
    display: none;
  }
  .sortingMobile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .sortingLabel {
    font-weight: 500;
    margin-right: 14px;
    white-space: nowrap;
  }
}
@media (max-width: 576px) {
  .grid {
    padding-top: 22px;
    padding-bottom: 0;
    grid-gap: 20px;
  }
  .doctor {
    margin: 0 -16px;
  }
  .doctorInfo {
    grid-gap: 8px;
    margin-bottom: 40px;
  }
  .doctorPersonalData,
  .appointments {
    border-radius: 0;
  }
  .doctorPersonalData {
    grid-gap: 13px;
    padding: 16px;
  }
  .doctorPersonalData > div:first-child {
    grid-area: inherit;
  }
  .doctorPatients {
    grid-template-columns: 1fr;
    justify-items: start;
  }
  .doctorPersonalGrid {
    grid-area: 2/1/3/3;
  }
  .pageTitle,
  .actions {
    padding: 0 16px;
    grid-gap: 20px;
    margin-bottom: 30px;
  }
}
