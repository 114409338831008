.container {
  background-color: var(--color-background-primary);
  border-radius: 20px;
  max-width: 832px;
  min-height: 500px;
  margin: auto;
  padding: 50px;
  text-align: center;
  box-shadow: var(--box-shadow-primary);
}
.header {
  max-width: 500px;
  margin: 0 auto 42px;
}
.title {
  font-size: 44px;
  margin-bottom: 20px;
  font-weight: 600;
}
.subtitle {
  color: var(--color-text-secondary);
}
.form {
  max-width: 400px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.formInHeader {
  display: grid;
  grid-template-columns: 200px 200px 150px max-content;
  align-items: center;
  grid-gap: 20px;
}

@media (max-width: 992px) {
  .formInHeader {
    grid-template-columns: 160px 160px 100px max-content;
    grid-gap: 10px;
  }
}
@media (max-width: 768px) {
  .header {
    margin: 0 auto 36px;
  }
  .title {
    font-size: 38px;
  }
}
@media (max-width: 576px) {
  .container {
    margin: 0 -16px;
    border-radius: 0;
    padding: 50px 16px;
    font-size: 14px;
    box-shadow: none;
  }
  .header {
    margin: 0 auto 24px;
  }
  .title {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 12px;
  }
}
