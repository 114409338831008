.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  padding: 30px;
}
.formContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.titleTimeStart {
  font-weight: 500;
  color: var(--color-text-secondary);
}
.titleTimeEnd {
  color: var(--color-text-secondary);
  font-weight: 700;
}
.actions {
  padding: 0;
  max-width: 830px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}
.actions.actionsGrid {
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 576px) {
  .form {
    padding: 20px;
  }
  .formContent {
    grid-gap: 10px;
  }
  .actions.actionsGrid,
  .actions {
    grid-template-columns: 1fr;
  }
}
