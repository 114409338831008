.wrapper {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.card {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  max-width: 600px;
}
.personalData {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 24px;
  max-width: 900px;
}
.personalData > div {
  grid-area: 1/1/3/2;
}
.title {
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  margin-bottom: 27px;
}
.personalData > .title {
  margin-bottom: 0px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.listItem {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  grid-gap: 15px;
}
.label {
  font-weight: 500;
}
.values {
  color: var(--color-text-secondary);
  display: flex;
  flex-wrap: wrap;
}
.values .valuesItem:not(:last-child) {
  padding-right: 10px;
}
.values .valuesItem:not(:last-child)::after {
  content: ',';
}
.values > a {
  color: var(--color-text-primary);
  font-size: 20px;
  transition: opacity 0.2s;
}
.values > a:hover {
  opacity: 0.7;
}
.values > a:not(:last-child) {
  margin-right: 10px;
}
.values > a[href*='youtube'] {
  font-size: 27px;
  position: relative;
  top: -3px;
}
.actions {
  display: flex;
}
.actions .button {
  max-width: 350px;
  width: 100%;
  padding: 13px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .personalData {
    grid-template-columns: 1fr;
  }
  .personalData > div {
    grid-area: inherit;
  }
  .actions {
    flex-direction: column;
  }
  .actions .button {
    margin-right: 0;
    margin-top: 20px;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 0 0 30px;
    grid-gap: 10px;
  }
  .card {
    margin: 0 -16px;
    border-radius: 0;
  }
  .title {
    font-size: 20px;
  }
  .listItem {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
