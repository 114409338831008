.wrapper {
  padding-bottom: 50px;
}
.header {
  margin-bottom: 32px;
}
.title {
  font-weight: 600;
  font-size: 32px;
  color: var(--color-text-primary);
}
.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
