.wrapper {
  padding: 50px 0;
}
.header {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
.title {
  font-size: 50px;
}
.subtitle {
  padding-top: 30px;
  font-size: 40px;
  margin-bottom: 25px;
}
.content p {
  text-indent: 24px;
  text-align: justify;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .title {
    font-size: 35px;
  }
  .subtitle {
    padding-top: 10px;
    font-size: 25px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 30px;
  }
  .subtitle {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
