.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}
.header {
  padding-top: 50px;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.actions {
  margin-bottom: 50px;
}

.content {
  background-color: var(--color-background-secondary);
  min-height: 350px;
}
.content .container {
  padding-top: 50px;
  padding-bottom: 100px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
}

.doctorList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding-bottom: 50px;
}
.grid {
  padding: 24px 34px 24px 32px;
  border-radius: 14px;
  background-color: var(--color-background-primary);
  display: grid;
  grid-template-columns: 1fr repeat(4, 210px);
}
.gridCell {
  position: relative;
}
.gridCell:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: block;
  width: 1px;
  height: 80%;
  background-color: var(--color-search-primary);
}
.gridCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gridCell:first-child {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 10px;
}
.gridCell:last-child {
  justify-content: flex-end;
}
.doctorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctorSpecialities {
  font-weight: 500;
  color: var(--color-text-secondary);
}
.doctorPhone {
  font-weight: 500;
  padding: 0 10px;
}
.doctorAddress {
  color: var(--color-text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 10px;
}
.gridCell > button {
  padding: 6px 28px;
}

.notFoundBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundBlockTitle {
  margin-bottom: 20px;
}
.notFoundBlock .button {
  width: 200px;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    align-items: end;
  }
  .gridCell {
    justify-content: flex-start;
  }
  .gridCell:not(:last-child)::after {
    display: none;
  }
  .gridCell:last-child {
    justify-content: flex-start;
  }
  .doctorPhone,
  .doctorAddress {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    padding: 14px;
  }
}

@media (max-width: 576px) {
  .content .container {
    padding-bottom: 50px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .doctorList {
    padding-bottom: 30px;
  }
  .gridCell > button {
    width: 100%;
  }
}
