.labValueWrapper {
  width: 100%;
  padding: 20px 48px;
  display: grid;
  grid-template-columns: 0.87fr 80px 50px 50px 1fr 1fr 80px;
  grid-gap: 20px;
  align-items: center;
}

.labName {
  font-weight: 600;
}
.labQty {
  display: block;
  padding: 5px 15px;
  background: var(--color-background-secondary);
  border-radius: 50px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  max-width: 100%;
}
.labUnit {
}
.labFlag {
}
.labReference,
.labDescription,
.labDate {
  font-size: 14px;
}
.labDate {
  font-weight: 500;
}

.labTitleCell {
  display: none;
  width: 78px;
  min-width: 78px;
  background-color: var(--color-text-primary);
  color: var(--color-text-accent);
  font-weight: 600;
  padding-left: 16px;
  margin-right: 16px;
  align-items: center;
  height: 100%;
}

@media (max-width: 992px) {
  .labValueWrapper {
    grid-template-columns: 1fr;
    border-bottom: 2px solid var(--color-text-primary);
    padding: 0;
    grid-gap: 0;
  }
  .labGridCell {
    display: flex;
    height: 100%;
    min-height: 67px;
    align-items: center;
    border-bottom: 1px solid var(--color-border-table-medical-reports);
    margin-bottom: -1px;
  }
  .labGridCell:last-child {
    border-bottom: none;
  }
  .labTitleCell {
    display: flex;
  }
  .labName {
    padding-right: 30px;
  }
}

@media print {
  .labTitleCell {
    border: 1px solid black;
    color: black;
  }
}
