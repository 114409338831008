.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 16px;
}
.titlePage {
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .titlePage {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .titlePage {
    font-size: 20px;
  }
}
