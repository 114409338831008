.wrapper {
  position: relative;
}
.loadImageWrapper {
  width: 142px;
  height: 142px;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addedImageWrapper {
  width: 100%;
  max-width: 240px;
  height: 93px;
  border-radius: 14px;
  border: 1px solid var(--color-input-border);
  position: relative;
  color: var(--color-text-accent);
}
.addedImageWrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.imageRemoveButton {
  position: absolute;
  top: 0;
  left: 101%;
  cursor: pointer;
  color: var(--color-input-placeholder);
  font-size: 14px;
}
.addImageWrapper {
  width: 142px;
  height: 142px;
  background-color: var(--color-border-primary);
  border: 1px solid var(--color-border-primary);
  border-radius: 14px;
  color: var(--color-text-secondary);
  font-size: 14px;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 0.2s;
  position: relative;
}
.addImage {
  margin-bottom: 32px;
}
.addImageWrapper:hover {
  border: 1px solid var(--color-input-border-focus);
}

.modalOverlay {
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: auto;
  padding: 10px;
}
.modalWindow:focus {
  outline: none;
}
.modalWindow {
  position: relative;
  max-width: 526px;
  width: 100%;
  background-color: var(--color-background-primary);
  padding: 42px 20px;
  border-radius: 13px;
  box-shadow: var(--box-shadow-primary);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-gap: 20px;
}
.modalClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modalHeader {
}
.modalTitle {
  font-weight: 600;
  font-size: 24px;
}
.modalBody {
  border: 1px solid var(--color-border-drop-zone);
  border-radius: 14px;
}

.modalFooter {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
}
.modalButton {
  width: 100%;
}

.errorMark {
  border: 1px solid var(--color-primary);
}
.errorMessage {
  font-size: 12px;
  padding-left: 16px;
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--color-primary);
}

.canvasSignature {
  width: 100%;
  height: 100%;
}

@media (max-width: 576px) {
  .modalWindow {
    padding: 30px 10px;
  }
}
