.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.downloadLink {
  display: flex;
}
.downloadLink:hover {
  color: var(--color-primary);
}
.wrapperField {
  cursor: pointer;
}
.input {
  display: none;
}
.wrapperIcon {
  width: 24px;
  height: 24px;
  display: inline-block;
}
.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.label {
  color: var(--color-text-secondary);
}
.progressBar {
  position: absolute !important;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 20px;
}
