.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.lineGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.flexGrid {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.flexGrid > button {
  padding: 12px 26px;
  width: 100%;
  max-width: 234px;
}
.lineGridSecond {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
  display: inline-block;
}
.lineSubGrid {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: start;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  padding-top: 50px;
}
.actions {
  padding: 30px 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 768px) {
  .lineGrid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
  .lineGridSecond {
    grid-template-columns: 1fr;
  }
  .flexGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .flexGrid > button {
    max-width: 100%;
  }
  .subtitle {
    font-size: 24px;
    padding-top: 40px;
  }
  .actions {
    grid-template-columns: 1fr;
  }
}
