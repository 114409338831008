.wrapper {
  position: relative;
  width: 142px;
  height: 142px;
}
.loadImageWrapper {
  width: 142px;
  height: 142px;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addedImageWrapper {
  width: 142px;
  height: 142px;
  border-radius: 14px;
  border: 1px solid var(--color-border-primary);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: var(--color-text-accent);
  font-size: 14px;
}
.addedImageWrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageRemoveButton {
  position: absolute;
  top: 0;
  left: 101%;
  cursor: pointer;
  color: var(--color-input-placeholder);
  font-size: 14px;
}
.overlayAddedImage {
  background: var(--color-overlay-added-image-desktop);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlayAddedImageText {
  margin-left: 10px;
}
.addImageWrapper {
  width: 142px;
  height: 142px;
  background-color: var(--color-border-primary);
  border: 1px solid var(--color-border-primary);
  border-radius: 14px;
  color: var(--color-text-secondary);
  font-size: 14px;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border 0.2s;
  position: relative;
}
.addImage {
  margin-bottom: 32px;
}
.addImageWrapper:hover {
  border: 1px solid var(--color-input-border-focus);
}

.modalOverlay {
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: auto;
  padding: 10px;
}
.modalWindow:focus {
  outline: none;
}
.modalWindow {
  position: relative;
  max-width: 526px;
  width: 100%;
  background-color: var(--color-background-primary);
  padding: 42px 20px;
  border-radius: 13px;
  box-shadow: var(--box-shadow-primary);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-gap: 20px;
  justify-items: center;
}
.modalClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modalHeader {
}
.modalTitle {
  font-weight: 600;
  font-size: 24px;
}
.modalDescription {
  font-size: 14px;
  color: var(--color-text-secondary);
  margin-bottom: 20px;
}
.modalBody {
  width: 100%;
}
.dragZone {
  border: 1px dashed var(--color-border-drop-zone);
  border-radius: 14px;
  width: 100%;
  max-width: 410px;
  height: 280px;
  margin: 0 auto 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.dragZone:focus {
  outline: none;
}
.dragOn {
  background-color: var(--color-background-drop-zone-hover);
}
.adviceImg {
  margin-bottom: 20px;
}
.advice {
  color: var(--color-advice-text);
  text-align: center;
  margin-bottom: 10px;
}
.smallButton {
  font-size: inherit;
  line-height: 1.25;
}

.modalFooter {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
}
.modalButton {
  width: 100%;
}
.cropWrapper {
  border-radius: 14px;
  width: 100%;
}
.cropWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.errorMark {
  border: 1px solid var(--color-primary);
}
.errorMessage {
  font-size: 12px;
  padding-left: 16px;
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--color-primary);
}

@media (max-width: 576px) {
  .overlayAddedImage {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: var(--color-overlay-added-image-mobile);
  }
  .overlayAddedImageText {
    display: none;
  }
  .modalWindow {
    padding: 30px 10px;
  }
  .modalDescription {
    margin-bottom: 10px;
  }
  .dragZone {
    height: 200px;
  }
}
