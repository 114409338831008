.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  font-size: inherit;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
}
.wrapperInput {
  position: relative;
  width: 100%;
}
.wrapperInput.errorInput .textInput {
  border: 1px solid var(--color-primary);
}
.textInput {
  width: 100%;
  font-size: inherit;
  padding: 12px 36px 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-input-border);
  background-color: var(--color-background-primary);
  line-height: 26px;
}
.textInput:last-child {
  padding: 12px 16px;
}
.textInput::placeholder {
  font-size: inherit;
  color: var(--color-input-placeholder);
}
.textInput:focus {
  outline: none;
  border: 1px solid var(--color-input-border-focus);
}
.textInput:disabled {
}
.icon {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  transform: translate(-16px, -50%);
  cursor: pointer;
}
.message {
  font-size: 12px;
  padding-left: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}
.message.error {
  color: var(--color-primary);
}
.message.description {
}
.forgotPassword {
  position: absolute;
  top: 0;
  right: 0;
}

/* checkbox */
.labelCheckbox {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  font-size: inherit;
  padding-left: 40px;
  cursor: pointer;
}
.checkboxInput {
  appearance: none;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: polygon(0, 0, 0, 0);
}
.checkboxInput:focus {
  outline: none;
}
.box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid var(--color-text-primary);
  overflow: hidden;
  background-color: var(--color-background-primary);
}
.checked {
  background-color: var(--color-text-primary);
}

/* radio */
.radioButtonGrid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 40px;
  padding: 15px;
  width: 100%;
  border-radius: 8px;
}
.radioButtonGrid.errorGroup {
  border: 1px solid var(--color-primary);
  padding: 14px;
}

/* textarea */
textarea.textInput {
  font-size: 16px;
  resize: none;
  letter-spacing: -0.5px;
}
textarea.textInput::placeholder {
  font-size: 16px;
}
