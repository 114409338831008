.wrapper {
  width: 210mm;
  height: 296mm;
  margin: 0 auto;

  padding: 10mm;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5mm;
  margin-bottom: 5mm;
  border-bottom: 1mm solid black;
}
.sizeLogo {
  width: 50mm;
}
.invoiceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.invoiceDate {
  font-weight: 600;
}
.dateLabel {
  font-size: 3mm;
  padding-right: 3mm;
}
.invoiceId {
  font-size: 3.5mm;
}
.members {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5mm;
}
.membersName {
  font-size: 5mm;
  font-weight: 900;
  text-transform: capitalize;
}
.subHeader {
  display: grid;
  grid-template-columns: 0.8fr repeat(2, 0.6fr);
  padding-bottom: 3mm;
  margin-bottom: 2mm;
  align-items: end;
  border-bottom: 0.5mm solid black;
}
.status {
  display: flex;
  align-items: flex-end;
}
.statusValue {
  padding: 1mm 5mm;
  border: 0.5mm solid black;
  border-radius: 100mm;
}
.left {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.totalWrapper {
  padding: 5mm 0;
}
.subtitle {
  font-size: 6mm;
  margin-bottom: 4mm;
}
.servicesList {
  margin-bottom: 10mm;
}
.serviceItem {
  border: 0.5mm solid black;
  margin-bottom: 3mm;
}
.serviceItem:not(:last-child) {
  border-bottom: 0.5mm solid black;
}
.serviceHeader {
  padding: 5mm;
  border-bottom: 0.5mm solid black;
}
.serviceBody {
  padding: 5mm;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3mm;
  align-items: end;
}
.label {
  font-weight: 600;
  padding-right: 5mm;
}
.value {
  font-size: 3.8mm;
}
.valueStatus {
  font-size: 6mm;
  font-weight: 900;
}
