.container {
}
.header {
  margin-bottom: 40px;
}
.title {
  font-weight: 600;
  font-size: 32px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 26px;
  max-width: 400px;
}
.form > .button {
  padding: 12px 16px;
}

@media (max-width: 1200px) {
  .container {
    margin: 0 auto;
    max-width: 400px;
  }
}
