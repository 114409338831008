.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.actions {
  padding: 30px 0 50px;
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 576px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .actions {
    grid-template-columns: 1fr;
  }
}
