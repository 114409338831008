.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.formSubGrid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  align-items: end;
}

@media (max-width: 992px) {
  .formSubGrid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  .formSubGrid {
    grid-template-columns: max-content 1fr;
  }
}
@media (max-width: 576px) {
  .formSubGrid {
    grid-template-columns: 1fr;
  }
}
