.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
  display: inline-block;
}
.primaryField {
  position: relative;
}
.listAutoComplete {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-background-primary);
  border-radius: 8px;
  z-index: 1;
  overflow: auto;
  box-shadow: var(--box-shadow-primary);
  max-height: 150px;
}
.itemAutoComplete {
  padding: 3px 16px;
  transition: background-color 0.2s;
}
.itemAutoComplete:hover {
  cursor: pointer;
  background-color: var(--color-input-border);
}
.progressBar {
  position: absolute !important;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
