.container {
  height: 100%;
}
.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  padding: 50px 0;
}
.header {
  margin-bottom: 20px;
}
.title {
  font-size: 32px;
  font-weight: 600;
}
.scrollContainer {
  min-height: 800px;
  max-height: 1000px;
  overflow: auto;
}
.content {
  height: 100%;
  background-color: var(--color-background-secondary);
  padding: 20px;
  border-radius: 14px;
  min-width: 830px;
}
