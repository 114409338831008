.wrapper {
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-input-border);
  border-radius: 14px;
  overflow: hidden;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 0.3fr 1fr 1.2fr 0.8fr;
  grid-gap: 10px;
  align-self: start;
  position: relative;
}
.forDoctor {
  grid-template-columns: 0.9fr 1.1fr 1.2fr 0.9fr;
}
.forPreview {
  grid-template-columns: 1fr 1fr;
}
.forPreview .cell:last-child {
  grid-area: 1/2/3/3;
}
.cell {
  position: relative;
}
.cell.personalInfo {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 12px;
  color: var(--color-text-primary);
  padding: 0;
}
.name {
  display: grid;
  grid-template-columns: 1fr;
  letter-spacing: 0.15px;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-text-secondary);
  padding-left: 5%;
}
.cell:last-child {
  justify-content: space-around;
  padding-right: 25px;
}
.cell:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translate(0, -50%);
  display: block;
  width: 1px;
  height: 80%;
  background-color: var(--color-input-border);
}
.forPreview .cell:not(:last-child)::after {
  display: none;
}
.cell .icon {
  margin-right: 5px;
  line-height: 1;
}
.cell .circleButton {
  border-radius: 50%;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
}
.cell.mark {
  color: var(--color-text-primary);
}
.duration {
  background-color: var(--color-background-secondary);
  border-radius: 20px;
  width: 60px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuButton {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.actionButton {
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}
.actionButton:hover {
  opacity: 0.7;
}
.accept {
  color: var(--color-accept);
}
.reject {
  color: var(--color-primary);
}
.menuItemStyle.menuItem {
  border-radius: 8px;
  overflow: hidden;
}
.menuItemStyle.menuItem.menuItemMark {
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .wrapper,
  .forDoctor,
  .forPreview {
    grid-template-columns: 1fr;
  }
  .forPreview .cell:last-child {
    grid-area: auto;
  }
  .cell:last-child {
    display: flex;
    justify-content: space-between;
    position: static;
  }
  .cell:not(:last-child)::after {
    display: none;
  }
  .menuButton {
    right: 5px;
    top: 10px;
    transform: translateY(0);
  }
  .cell .circleButton {
    margin-right: 0;
  }
  .cell,
  .cell:last-child {
    padding: 0;
  }
}
