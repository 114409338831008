.container {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.title {
  font-size: 44px;
  margin-bottom: 50px;
}
.grid {
  display: grid;
  grid-template-columns: 233px 1fr;
  grid-gap: 90px;
}
.settingMenu {
}
.settingList {
  width: 100%;
  color: var(--color-advice-text);
}
.settingItem {
  padding: 14px;
  border-radius: 14px;
  overflow: hidden;
  font-weight: 600;
  transition: background-color 0.2s;
}
.settingItem:not(.activeSetting) {
  cursor: pointer;
}
.settingItem:not(.activeSetting):hover {
  background-color: var(--color-input-border);
}
.activeSetting {
  background-color: var(--color-background-secondary);
  color: var(--color-text-primary);
}
.settingMenu .settingListSelect {
  display: none;
}
.settingItemSelect {
}
.content {
}

@media (max-width: 1200px) {
  .title {
    margin-bottom: 30px;
  }
  .grid {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .settingList {
    display: none;
  }
  .settingMenu .settingListSelect {
    display: inline-flex;
  }
}
