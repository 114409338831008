.container {
  padding: 50px 0;
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}

@media (max-width: 576px) {
  .title {
    font-size: 32px;
    margin-bottom: 32px;
  }
}
