.footer {
  padding-top: 36px;
  background-color: var(--color-text-primary);
  color: var(--color-text-accent);
}
.footerGrid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.footerWrapperLogo {
  max-width: 250px;
}
.footerWrapperLogo p {
  opacity: 0.7;
  line-height: 26px;
}
.sizeLogo {
  max-width: 154px;
  width: 100%;
  margin-bottom: 20px;
}
.listsWrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 30px;
}
.address {
  font-style: inherit;
  display: inline;
}
.footerDataItem {
  display: grid;
  grid-template-columns: 75px 175px;
  grid-gap: 10px;
  margin-bottom: 15px;
}
.footerDataLinkText,
.address {
  opacity: 0.7;
}
.socialNetworksItem {
  margin-bottom: 15px;
}
.socialNetworksLinkTitle {
  opacity: 0.7;
  margin-left: 10px;
}

.footerDown {
  padding: 20px 0;
  border-top: 2px solid var(--color-text-accent);
  opacity: 0.5;
  font-size: 14px;
}
.footerDownGrid {
  display: flex;
  justify-content: space-between;
}
.footerDownLink a:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .footerGrid {
    flex-direction: column;
  }
  .footerWrapperLogo {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .footerDownGrid {
    flex-direction: column-reverse;
  }
}

@media (max-width: 576px) {
  .footer {
    padding-top: 0;
  }
  .listsWrapper {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .socialNetworksList {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 30px;
  }
  .socialNetworksLinkTitle {
    display: none;
  }
  .footerDownLink {
    display: flex;
    flex-direction: column;
  }
}

@media print {
  .footer {
    display: none;
  }
}
