.container {
  height: 100%;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 322px;
  grid-template-rows: max-content 1fr;
  grid-gap: 31px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
  grid-template-areas:
    'header sidebar'
    'body sidebar';
}
.sidebar {
  grid-area: sidebar;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 31px;
}

/* patient */
.patientInfo {
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-gap: 30px;
}
.patientPersonalData {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 24px;
}
.patientPersonalGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 15px;
}
.patientName {
  font-size: 20px;
  text-transform: capitalize;
}
.specialInfo {
}
.specialInfoLabel {
  font-size: inherit;
  margin-bottom: 5px;
}
.specialName {
  color: var(--color-text-secondary);
}
.specialName:not(:last-child)::after {
  content: ', ';
}

/* pageWrapper */
.pageWrapper {
  grid-area: body;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr;
  padding-top: 50px;
}
.pageTitle {
  font-size: 32px;
  margin-bottom: 28px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 60px;
  margin-bottom: 40px;
  align-items: end;
}
.actions .addButton {
  width: 282px;
  padding: 14px 16px;
}

/* appointments (Tabs) */
.appointments {
  background-color: var(--color-background-secondary);
  padding: 24px;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr max-content;
}
.appointmentsTabs {
  background-color: var(--color-background-primary);
  border-radius: 200px;
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 28px;
}
.appointmentsTabs .appointmentsTabsButton {
  text-transform: capitalize;
}
.sorting {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
}
.sortingMobile {
  display: none;
}
.sortingBy {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.sortingBy .sortingIcon {
  color: var(--color-text-primary);
  font-size: 16px;
}
.tabsContainer {
  min-height: 500px;
}
.appointmentsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  align-items: start;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

/* activities */
.activities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.activity {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 22px;
}
.activityHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activityTitle {
  font-weight: 600;
  font-size: 20px;
}
.activityButton {
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}
.activityButton:hover {
  opacity: 0.7;
}
.activityContent {
}
.activityList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
.notFoundBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundBlockTitle {
  margin-bottom: 20px;
}
.labPreview {
  background-color: var(--color-background-primary);
  border-radius: 14px;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.labPreviewTitle {
  font-weight: 600;
}
.labPreviewDate {
  font-size: 14px;
  color: var(--color-text-secondary);
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'sidebar'
      'body';
  }
  .sidebar {
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
  }
  .patientInfo {
    grid-template-columns: repeat(3, 1fr);
  }
  .patientPersonalData {
    grid-area: 1/1/2/3;
  }
  .pageWrapper {
    padding-top: 0;
  }
}
@media (max-width: 992px) {
  .patientInfo {
    grid-template-columns: 1fr;
  }
  .patientPersonalData {
    grid-area: auto;
  }
  .sidebar {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .actions {
    grid-template-columns: 1fr;
  }
  .actions .addButton {
    width: 100%;
  }
  .appointmentsTabs {
    overflow: auto;
  }
  .appointmentsTabsButton {
    width: 250px;
  }
  .sorting {
    display: none;
  }
  .sortingMobile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .sortingLabel {
    font-weight: 500;
    margin-right: 14px;
    white-space: nowrap;
  }
}
@media (max-width: 576px) {
  .grid {
    padding-top: 22px;
    padding-bottom: 0;
    grid-gap: 8px;
    margin: 0 -16px;
  }
  .sidebar {
    grid-gap: 8px;
  }
  .patientInfo {
    grid-gap: 8px;
  }
  .patientPersonalData,
  .appointments,
  .activity {
    border-radius: 0;
    padding: 16px;
  }
  .patientPersonalData {
    grid-template-columns: 1fr;
  }
  .pageTitle,
  .actions {
    padding: 0 16px;
    grid-gap: 20px;
  }
}
