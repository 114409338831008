.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subtitle {
  font-weight: 600;
  font-size: 20px;
}
.table {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
}
.cellHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent);
  color: var(--color-text-accent);
  height: 45px;
  font-weight: 600;
}
.sellContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-background-secondary);
  border: 1px solid var(--color-input-border);
  border-radius: 0 0 14px 14px;
  color: var(--color-text-primary);
  font-weight: 500;
  font-size: 14px;
  height: 110px;
}
.description {
  font-size: 14px;
  font-weight: 500;
}
.description .mark {
  color: var(--color-text-secondary);
}

@media (max-width: 768px) {
  .table {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
  .cell {
    display: grid;
    grid-template-columns: 58px 1fr;
    grid-template-rows: 58px;
  }
  .cellHeader {
    height: auto;
  }
  .sellContent {
    height: auto;
    border-radius: 0 14px 14px 0;
    flex-direction: row;
  }
}
