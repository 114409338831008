.wrapper {
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;
}
.title {
  font-weight: 600;
  font-size: 32px;
}
.actions {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 26px;
}
.actions .addButton {
  width: 282px;
  padding: 14px;
}
.content {
}
.labValueWrapper {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--color-border-table-medical-reports);
}
.labValueWrapper:last-child {
  border-bottom: none;
}
.labHeader {
  background-color: var(--color-text-primary);
  color: var(--color-text-accent);
  font-weight: 600;
  border-radius: 14px;
  padding: 20px 48px;
  display: grid;
  grid-template-columns: 0.87fr 80px 50px 50px 1fr 1fr 80px;
  grid-gap: 20px;
  align-items: center;
}
.widthFull .downloadLink {
  padding: 3px 13px;
  min-width: auto;
}
.widthFull .downloadLink:not(:last-of-type) {
  margin-right: 10px;
}
.blockTitle {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  width: 100%;
}
.menuButton {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.widthFull {
  width: 100%;
}

.descriptionWrapper {
  padding: 20px;
  border-bottom: 1px solid var(--color-border-table-medical-reports);
  white-space: pre-line;
}
.description {
  text-indent: 15px;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.notFoundBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.notFoundBlockTitle {
  font-weight: 400;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.menuItemStyle.menuItem {
  border-radius: 8px;
  overflow: hidden;
}
.menuItemStyle.menuItem.menuItemMark {
  color: var(--color-primary);
}
.menuItemStyle.menuItem.menuItemMarkAccent {
  color: var(--color-accept);
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 20px;
  }
  .labHeader {
    display: none;
  }
  .labValueWrapper {
    border-bottom: none;
  }
  .menuButton {
    top: 15px;
    right: 10px;
    transform: translateY(0);
  }
  .actions {
    width: 100%;
    grid-template-columns: 1fr max-content;
  }
}

@media (max-width: 768px) {
  .actions {
    grid-template-columns: 1fr;
  }
  .actions .addButton {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .header {
    padding: 0 16px;
  }
  .title {
    font-size: 28px;
  }
}
