.toolbarWrapper {
  padding: 20px;
  background-color: var(--color-background-primary);
  border-radius: 14px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
}
.toolbarWrapper .button {
  text-transform: capitalize;
}
.toolbarWrapper .button:disabled {
  color: var(--color-text-accent);
  background-color: var(--color-accent);
}
