.wrapper {
  padding: 50px 0;
  max-width: 830px;
  margin: 0 auto;
}
.title {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 74px;
}
.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
}
.doctor {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 32px;
}
.doctorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctorName {
  font-size: 32px;
}
.doctorValues {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 10px;
}
.label {
  font-weight: 500;
}
.values {
  color: var(--color-text-secondary);
}
.value:not(:last-child)::after {
  content: ', ';
}

@media (max-width: 768px) {
  .doctorValues {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .values {
    padding-left: 10px;
  }
  .label {
    padding-top: 10px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 20px 0;
  }
  .title {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .content {
    grid-gap: 20px;
  }
  .doctor {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .doctorInfo {
    display: block;
  }
  .doctorName {
    font-size: 24px;
  }
}
