.wrapper {
  height: 100%;
}
.wrapperGrid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
}
.header {
  padding: 50px 0;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
}
.contents {
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
}
