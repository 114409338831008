.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg > div {
  background: #2a64d1 none repeat scroll 0 0;
  border-radius: 14px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;

}
.time_date {
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 75%;
  overflow: hidden;
  margin-bottom: 15px;
}

.sent_msg > div {
  background: #ebebeb none repeat scroll 0 0;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 14px;
  border: 1px solid #eaebf0;
}
.outgoing_msg {
  overflow: hidden;
  margin-bottom: 15px;
}
.sent_msg {
  display: inline-block;
  float: right;
  width: 70%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.picture_preview {
  width: 250px;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.picture_preview {
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  filter: blur(2px);
}

.media_icon:hover .picture_preview {
  filter: blur(0px);
}

.picture_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media {
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.placeholder {
  background: rgba(22, 46, 52, 0.58);
}

.medias {
  display: flex;
  justify-content: start;
  align-content: center;
  flex-flow: wrap;
}

.media_icon_white {
  fill: white
}
