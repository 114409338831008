.section {
  margin-bottom: 100px;
}
.sectionGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.sectionTitle {
  font-size: 44px;
  margin-bottom: 20px;
  font-weight: 600;
}
.sectionSubtitle {
  color: var(--color-text-secondary);
  margin-bottom: 42px;
}
.sectionAccent {
  color: var(--color-text-accent);
  background: center / cover no-repeat;
  min-height: 360px;
  display: flex;
  align-items: center;
}
.sectionAccent .sectionSubtitle {
  color: inherit;
}
.sectionRecord {
  text-align: center;
}

.sectionInfo {
  padding-top: 150px;
}
.searchGroup {
  margin-bottom: 120px;
}
.performGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.performGridCell {
  display: flex;
  flex-direction: column;
}
.performInfoQuantity {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 600;
}
.performInfo {
  font-weight: 600;
}
.sectionInfoImg {
  position: relative;
  left: 50px;
}

.cardsSection {
  margin-bottom: 100px;
}
.cardsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 100%;
}
.card {
  background-color: var(--color-secondary);
  padding: 35px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
.cardIcon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
}
.cardDescription {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: var(--color-text-accent);
}

.sectionEnrolment {
  padding-top: 60px;
  max-width: 500px;
  justify-self: end;
}
.sectionEnrolmentGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding-top: 40px;
}
.sectionEnrolmentIcon {
  width: 30px;
  margin-bottom: 26px;
}
.sectionEnrolmentDescription {
  font-weight: 600;
}

.appointments {
  max-width: 70%;
  margin: auto;
}
.appointmentsButton {
  max-width: 346px;
  width: 100%;
}

.recordGrid {
  display: flex;
  justify-content: space-around;
}
.recordCell {
  padding: 20px;
  max-width: 270px;
}
.recordIcon {
  border-radius: 50%;
  overflow: hidden;
  width: 54px;
  height: 54px;
  margin: 0 auto 16px;
}
.recordDescription {
  font-weight: 600;
}

.sectionImages {
  position: relative;
}
.imagePatients {
  width: 100%;
}
.imageDoctor {
  position: absolute;
  top: 100px;
  left: -40px;
  max-width: 250px;
}
.imageChat {
  max-width: 205px;
  position: absolute;
  right: -60px;
  bottom: 0;
}
.gradientVerticalWhiteGray {
  padding-bottom: 100px;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 30%, #f7f7f9 30%, #f7f7f9 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 30%, #f7f7f9 30%, #f7f7f9 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #f7f7f9 30%, #f7f7f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#F7F7F9',GradientType=0 );
}

.gradientVerticalGrayMirage {
  padding-bottom: 100px;
  background: #f7f7f9;
  background: -moz-linear-gradient(top, #f7f7f9 0%, #f7f7f9 50%, #1d2239 50%, #1d2239 100%);
  background: -webkit-linear-gradient(top, #f7f7f9 0%, #f7f7f9 50%, #1d2239 50%, #1d2239 100%);
  background: linear-gradient(to bottom, #f7f7f9 0%, #f7f7f9 50%, #1d2239 50%, #1d2239 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f9', endColorstr='#1d2239',GradientType=0 );
}

@media (max-width: 1200px) {
  .section {
    margin-bottom: 60px;
  }
  .sectionGrid {
    grid-template-columns: 1fr;
  }
  .sectionImg {
    display: none;
  }
  .sectionInfo {
    padding-top: 50px;
  }
  .searchGroup {
    margin-bottom: 50px;
  }
  .cardsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    grid-template-columns: max-content 1fr;
    align-items: center;
  }
  .sectionEnrolment {
    padding-top: 0;
    justify-self: left;
    max-width: 100%;
  }
  .sectionEnrolmentGrid {
    grid-template-columns: repeat(4, 1fr);
  }
  .appointments {
    max-width: 100%;
    margin: 0;
  }
  .imageChat {
    right: 0;
  }
  .imageDoctor {
    left: 0px;
  }
  .gradientVerticalWhiteGray,
  .gradientVerticalGrayMirage {
    padding-bottom: 60px;
  }
}
@media (max-width: 992px) {
  .imageChat {
    right: 30px;
    max-width: 180px;
  }
  .imageDoctor {
    left: 20px;
    max-width: 200px;
  }
  .card {
    grid-template-columns: 1fr;
    align-items: start;
  }
}
@media (max-width: 768px) {
  .sectionTitle {
    font-size: 38px;
  }
  .sectionInfo {
    padding-top: 25px;
  }
  .sectionEnrolmentGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .imageChat {
    bottom: -30px;
    max-width: 150px;
  }
  .imageDoctor {
    top: 50px;
    max-width: 180px;
  }
}
@media (max-width: 576px) {
  .sectionTitle {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .sectionSubtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .searchGroup {
    margin-bottom: 36px;
  }
  .performInfoQuantity {
    font-size: 20px;
  }
  .cardsSection {
    margin-bottom: 60px;
  }
  .cardsGrid {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
  .card {
    padding: 20px;
    grid-template-columns: max-content 1fr;
    grid-gap: 20px;
    align-items: center;
  }
  .cardDescription {
    font-size: 16px;
    line-height: 24px;
  }
  .sectionEnrolmentGrid {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    padding-top: 0;
  }
  .sectionEnrolmentCell {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 20px;
    align-items: center;
  }
  .sectionEnrolmentIcon {
    margin-bottom: 0;
  }
  .sectionAccent {
    min-height: 334px;
  }
  .sectionAccent .sectionTitle {
    font-size: 24px;
    margin-bottom: 18px;
  }
  .sectionAccent .sectionSubtitle {
    font-size: 16px;
  }
  .sectionRecord {
    text-align: left;
  }
  .sectionRecord .sectionSubtitle {
    margin-bottom: 40px;
  }
  .recordGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  .recordCell {
    max-width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    align-items: center;
  }
  .recordIcon {
    margin-bottom: 0;
  }
  .sectionImages {
    margin: 0 -30px;
  }
  .imageDoctor {
    max-width: 30%;
    top: -5%;
    left: 8%;
  }
  .imageChat {
    max-width: 22%;
  }
}
