.cardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}
.card {
  background-color: var(--color-background-primary);
  border-radius: 14px;
  padding: 10px;
  box-shadow: var(--box-shadow-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  height: 325px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.2s;
}
.card:first-child {
  justify-self: end;
}
.card:hover {
  transform: scale(1.02);
}
.cardWrapperImg {
  max-width: 72px;
  margin-bottom: 40px;
}
.cardTitle {
  font-weight: 600;
  font-size: 32px;
}

@media (max-width: 768px) {
  .card {
    max-width: 288px;
    height: 200px;
    border-radius: 7px;
  }
  .cardWrapperImg {
    margin-bottom: 18px;
  }
  .cardTitle {
    font-weight: 600;
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .cardGrid {
    grid-template-columns: 1fr;
    grid-gap: 17px;
    padding: 46px 16px;
    margin: 0 -16px;
    background-color: var(--color-text-primary);
  }
  .card,
  .card:first-child {
    justify-self: center;
  }
}
