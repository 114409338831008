.wrapper {
  position: relative;
  padding: 70px 50px 40px;
  max-width: 720px;
}
.buttonClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.header {
  margin-bottom: 75px;
}
.title {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  padding: 0 20px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
}
.formGrid {
  display: grid;
  grid-template-columns: 0.95fr 1.05fr;
  grid-gap: 15px;
}
.formSubGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}
.actions .button {
  padding: 12px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 50px 30px 40px;
  }
  .header {
    margin-bottom: 30px;
  }
  .form {
    grid-gap: 30px;
  }
  .formGrid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .actions {
    grid-gap: 12px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 40px 20px 30px;
  }
  .title {
    font-size: 20px;
  }
  .formSubGrid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}
