.wrapper {
  padding: 50px 0;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 70px;
}
.header {
  border-bottom: 2px solid var(--color-background-secondary);
  padding: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 44px;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
}

.dateWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.dateLabel {
  font-weight: 600;
}
.dateValue {
  color: var(--color-text-secondary);
}

/* members */
.members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 150px;
}
.member {
}
.memberInfo {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.memberGrid {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  grid-gap: 20px;
}
.memberGrid:first-child {
  padding-bottom: 20px;
}
.memberGrid:first-child > .value {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-secondary);
  text-transform: capitalize;
}
.name {
  font-weight: 500;
}
.value {
  color: var(--color-text-secondary);
}

.text {
  color: var(--color-text-secondary);
  white-space: pre-line;
}

.images:not(:empty) {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.images:not(:empty) > div {
  margin-right: 30px;
}

.signatureTitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 26px;
}
.signatureImage {
  max-width: 300px;
}
.actions {
  padding: 0;
  max-width: 830px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
.actions .button {
  width: 100%;
  max-width: 400px;
  padding: 14px;
}
.actions.actionsGrid {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 992px) {
  .members {
    grid-template-columns: 1fr;
    grid-gap: 70px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 32px 0;
    grid-gap: 40px;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .members {
    grid-gap: 40px;
  }
  .memberGrid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .memberGrid:first-child {
    padding-bottom: 10px;
  }
  .images:not(:empty) {
    padding-top: 28px;
  }
  .images:not(:empty) > div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .signatureTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .actions,
  .actions.actionsGrid {
    grid-template-columns: 1fr;
  }
  .actions .button {
    max-width: 100%;
  }
}

@media print {
  .actions {
    display: none;
  }
}
