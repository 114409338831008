.custom-event {
  position: relative;
  background-color: var(--color-background-primary);
  color: var(--color-text-primary);
  border-radius: 6px;
  padding: 3px 5px 3px 10px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  transition: box-shadow 0.2s;
}
.custom-event:hover,
.custom-event:focus {
  outline: none;
  box-shadow: var(--box-shadow-primary);
  z-index: 1;
}
.custom-event:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 5px;
  background-color: var(--color-text-primary);
}
.custom-event .rbc-event-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.custom-event .rbc-event-content {
  color: var(--color-text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-event.custom-event-selected {
  background-color: var(--color-background-primary);
}
.custom-event.custom-event-selected:focus {
}
.custom-slot {
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
}
.custom-day {
}
.custom-day .rbc-current-time-indicator {
  background-color: var(--color-primary);
}
.custom-day.rbc-today {
  background-color: var(--color-secondary-opacity);
}
.custom-day .custom-event {
  border: none;
}
