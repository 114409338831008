.search {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  align-items: center;
  width: 100%;
  border: 1px solid var(--color-search-primary);
  border-radius: 100px;
  padding: 8px;
}
.wrapperFormControl {
  position: relative;
  border-radius: 100px;
}
.wrapperFormControl:not(:last-of-type)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translate(0, -50%);
  display: block;
  width: 1px;
  height: 80%;
  background-color: var(--color-search-primary);
}
.search .searchButton {
  padding: 9px 16px;
}

@media (max-width: 576px) {
  .search {
    grid-template-columns: 1fr;
    border: none;
  }
  .wrapperFormControl {
    border: 1px solid var(--color-search-primary);
  }
  .wrapperFormControl:not(:last-of-type)::after {
    display: none;
  }
}
