.prescriptionWrapper {
  padding: 24px 34px 24px 32px;
  border-radius: 14px;
  background-color: var(--color-background-primary);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 20px;
  transition: box-shadow 0.2s;
  cursor: pointer;
}
.prescriptionWrapper:hover {
  box-shadow: var(--box-shadow-primary);
}
.doctorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctorName {
  font-weight: 600;
}
.doctorSpeciality {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-secondary);
}
.dateCreate {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-secondary);
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  grid-area: 2/1/3/4;
}
.prescriptionWrapper.mobileView {
  padding: 16px;
  grid-gap: 16px;
}
.prescriptionWrapper.mobileView .dateCreate {
  grid-area: 3/1/3/4;
}
.prescriptionWrapper.mobileView .description {
  -webkit-line-clamp: 2;
}

@media (max-width: 576px) {
  .prescriptionWrapper {
    padding: 16px;
    grid-gap: 16px;
  }
  .dateCreate {
    grid-area: 3/1/3/4;
  }
  .description {
    -webkit-line-clamp: 2;
  }
}
