.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  justify-items: start;
  align-items: start;
  font-size: inherit;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
}
.wrapperInput {
  position: relative;
  width: 100%;
}
.wrapperInput.errorInput .textInput {
  border: 1px solid var(--color-primary);
}
.textInput {
  width: 100%;
  font-size: inherit;
  padding: 12px 36px 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-input-border);
  background-color: var(--color-background-primary);
  line-height: 26px;
}
.textInput::placeholder {
  font-size: inherit;
  color: var(--color-input-placeholder);
}
.textInput:focus {
  outline: none;
  border: 1px solid var(--color-input-border-focus);
}
.textInput:focus + .icon .iconMat {
  color: var(--color-input-border-focus);
}
.textInput:disabled {
}
.icon {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  transform: translate(-16px, -50%);
  cursor: pointer;
}
.iconMat {
  color: var(--color-input-placeholder);
}
.message {
  font-size: 12px;
  padding-left: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}
.message.error {
  color: var(--color-primary);
}
.message.description {
}

.chip {
  margin-bottom: 10px;
}
.chip + .chip {
  margin-left: 10px;
}

.listAutoComplete {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-background-primary);
  border-radius: 8px;
  z-index: 1;
  overflow: auto;
  box-shadow: var(--box-shadow-primary);
  max-height: 150px;
}
.itemAutoComplete {
  padding: 3px 16px;
  transition: background-color 0.2s;
}
.itemAutoComplete:hover {
  cursor: pointer;
  background-color: var(--color-input-border);
}
.progressBar {
  position: absolute !important;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 20px;
}
