.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalWindow:focus {
  outline: none;
}
.modalWindow {
  position: relative;
  max-width: 526px;
  width: 100%;
  min-height: 304px;
  background-color: var(--color-background-primary);
  padding: 72px 20px 42px;
  border-radius: 13px;
  box-shadow: var(--box-shadow-primary);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  margin: 16px;
}
.modalClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modalHeader {
  margin-bottom: 19px;
}
.modalTitle {
  font-weight: 600;
  font-size: 24px;
}
.modalDescription {
  font-size: 14px;
  color: var(--color-text-secondary);
}
.modalFooter {
  padding-top: 20px;
}
.modalFooterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}
.modalButton {
  max-width: 302px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .modalButton {
    max-width: 100%;
  }
}
