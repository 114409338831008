.form {
  width: 600px;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
}
.lineGrid {
  display: grid;
  grid-template-columns: 1fr minmax(100px, 0.5fr);
  grid-gap: 20px;
}
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions .button {
  width: 100%;
}

@media (max-width: 768px) {
  .form {
    width: 500px;
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .subtitle {
    font-size: 24px;
  }
  .form {
    width: 100%;
    padding: 20px;
  }
  .lineGrid {
    grid-template-columns: 1fr;
  }
  .actions {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
