.container {
}
.header {
  margin-bottom: 32px;
}
.title {
  font-weight: 600;
  font-size: 32px;
  color: var(--color-text-primary);
}
.subtitle {
  padding-top: 20px;
  font-weight: 600;
  font-size: 20px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.actions {
  padding: 30px 0 50px;
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}
.actionsSettings {
  grid-template-columns: 1fr;
  max-width: 400px;
}

@media (max-width: 576px) {
  .actions {
    grid-template-columns: 1fr;
  }
}
