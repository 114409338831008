.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  font-size: inherit;
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 8px;
}
.wrapperInput {
  position: relative;
  width: 100%;
}
.wrapperInput.errorInput .textInput {
  border: 1px solid var(--color-primary);
}
.textInput {
  width: 100%;
  font-size: inherit;
  padding: 12px 36px 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-input-border);
  background-color: var(--color-background-primary);
  line-height: 26px;
}
.textInput:last-child {
  padding: 12px 16px;
}
.textInput::placeholder {
  font-size: inherit;
  color: var(--color-input-placeholder);
}
.textInput:focus {
  outline: none;
  border: 1px solid var(--color-input-border-focus);
}

.message {
  font-size: 12px;
  padding-left: 16px;
  position: absolute;
  top: 100%;
  left: 0;
}
.message.error {
  color: var(--color-primary);
}
.message.description {
}
