.wrapper {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 120px;
}

/* patient info */
.patientWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.patientGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
/* patient personal data */
.personalData {
  border-radius: 14px;
  padding: 24px;
  background-color: var(--color-background-secondary);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  grid-area: 1/1/3/2;
}
.personalInfo {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 24px;
}
.name {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}
.data {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 3px;
  align-items: end;
}
.dataName {
  font-weight: 500;
}
.dataValue {
  color: var(--color-text-secondary);
}
.specialInfo {
  background-color: var(--color-background-primary);
  padding: 20px;
  border-radius: 21px;
}
.specialInfoLabel {
  font-weight: 500;
}
.specialName {
  color: var(--color-text-secondary);
}
.specialName:not(:last-child)::after {
  content: ', ';
}
.createAppointmentButton {
}

/* patient contacts */
.contacts {
  border-radius: 14px;
  padding: 24px;
  background-color: var(--color-background-secondary);
}
.contactsTitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}
.contactsDataWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}
.contactsData {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}
.contactsDataName {
  font-weight: 500;
}
.contactsDataValue {
  color: var(--color-text-secondary);
}

/* patient parameters */
.parameters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.parameter {
  border-radius: 14px;
  padding: 24px;
  background-color: var(--color-background-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
}
.parameterIcon {
  max-width: 25px;
  width: 100%;
}
.parameterIcon > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.parameterTitle {
  font-weight: 600;
}
.parameterValue {
  font-weight: 600;
  font-size: 32px;
}
.parameterUnit {
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text-secondary);
  padding-left: 8px;
}

/* activities */
.activities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.activity {
  background-color: var(--color-background-secondary);
  border-radius: 14px;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-gap: 22px;
}
.activityHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.activityHeader .button {
  margin-right: 15px;
}
.activityTitle {
  font-weight: 600;
  font-size: 20px;
}
.activityButton {
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}
.activityButton:hover {
  opacity: 0.7;
}
.activityContent {
}
.activityList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
.tabs {
  background-color: var(--color-background-primary);
  border-radius: 200px;
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 28px;
}
.tabs .tabsButton {
  text-transform: capitalize;
}
.appointmentsList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  align-items: start;
}
.notFoundBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundBlockTitle {
  margin-bottom: 20px;
}
.lab {
}

@media (max-width: 1200px) {
  .activities {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 992px) {
  .patientGrid {
    grid-template-columns: 1fr;
  }
  .personalData {
    grid-area: auto;
  }
}

@media (max-width: 576px) {
  .wrapper {
    margin: 0 -16px;
    padding: 20px 0;
    grid-gap: 50px;
  }
  .patientWrapper,
  .patient,
  .activities {
    grid-gap: 16px;
  }
  .personalInfo {
    grid-gap: 16px;
  }
  .dataValue {
    font-size: 14px;
  }
  .parameters {
    grid-gap: 16px;
    padding: 0 16px;
  }
  .parameter {
    min-height: 140px;
  }
  .parameterValue {
    font-size: 24px;
  }
  .personalData,
  .contacts,
  .activity {
    border-radius: 0;
    padding: 16px;
  }
}
