.wrapper {
  padding: 24px 34px 24px 32px;
  border-radius: 14px;
  background-color: var(--color-background-primary);
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  align-items: center;
}
.user {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  align-items: center;
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.userName {
  font-weight: 600;
  font-size: 20px;
}
.event {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.eventType {
  font-weight: 600;
}
.eventType.confirmed {
  color: var(--color-accept);
}
.eventType.rejected {
  color: var(--color-primary);
}
.eventType.canceled {
  color: var(--color-advice-text);
}
.eventPerson {
}

@media (max-width: 576px) {
  .wrapper {
    padding: 16px;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .event {
    align-items: flex-start;
  }
}
