.wrapper {
  height: 100%;
}
.container {
  height: 100%;
  padding: 50px 0;
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.content {
}
@media (max-width: 576px) {
  .title {
    font-size: 32px;
    margin-bottom: 32px;
  }
}
