.wrapper {
  width: 33px;
  height: 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.wrapper > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.wrapper.h {
  background-color: rgba(209, 59, 42, 0.1);
  color: #d13b2a;
}
.wrapper.l {
  background-color: rgba(42, 100, 209, 0.1);
  color: #2a64d1;
}
.wrapper.n {
  background-color: rgba(19, 189, 125, 0.1);
  color: #13bd7d;
}
