.container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.wrapper {
  padding: 50px 0;
  width: 100%;
  max-width: 830px;
  margin: 0 auto;
}
.title {
  font-weight: 600;
  font-size: 44px;
  margin-bottom: 50px;
}
.subtitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
}
.form {
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 50px;
}
.blockImages {
  padding: 30px 0 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.blockImages > div:not(:last-child) {
  margin-left: 32px;
}
/* .blockImages > div {
  margin-left: 32px;
} */
.blockImage {
  padding: 30px 0 50px;
}
.blockImageTitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
}

.actions {
  padding: 30px 0 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.actions > .button {
  padding: 12px 16px;
}

@media (max-width: 576px) {
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapper {
    padding: 0;
  }
  .title {
    font-size: 34px;
    margin-bottom: 30px;
  }
  .subtitle {
    font-size: 22px;
    margin-bottom: 22px;
  }
  .blockImageTitle {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .grid,
  .actions {
    grid-template-columns: 1fr;
  }
  .blockImages {
    padding: 30px 0;
    flex-direction: column-reverse;
  }
  .blockImages > div:not(:last-child) {
    margin-left: 0;
  }
  .blockImages > div:not(:first-child) {
    margin-bottom: 32px;
  }
}
