.plug {
  min-height: 64px;
}
.plug.landingHeaderPlug:not(.mobilePlug) {
  min-height: 94px;
}
.headerRoot {
  transition: min-height 0.5s, transform 0.5s;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-background-primary);
  border-bottom: 2px solid var(--color-border-primary);
  transform: translateY(-100%);
  z-index: 100;
  min-height: 64px;
  display: flex;
  align-items: center;
}
.headerRoot.scrollTop:not(.mobile) {
  border: none;
  min-height: 94px;
}
.headerRoot.scrollUp {
  transform: translateY(0);
}
.headerGrid {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

/* logo */
.positionLogo {
  position: absolute;
  top: 50%;
  left: 0;
  transition: top 0.5s;
  transform: translateY(-50%);
}
.headerRoot.scrollTop .positionLogo {
  top: 50px;
}
.sizeLogo {
  max-width: 331px;
  width: 100%;
  transition: max-width 0.5s;
}
.headerRoot:not(.scrollTop) .sizeLogo {
  max-width: 158px;
}
.headerRoot:not(.scrollTop):not(.landingHeader):not(.mobile) .sizeLogo {
  max-width: 110px;
}

/* right side */
.viewRightSide {
  justify-self: end;
}
.headerForm {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: 20px;
}
.button {
  width: 150px;
  max-height: 22px;
}
.link {
  font-weight: 500;
}

@media (max-width: 1200px) {
  .headerRoot.scrollTop .positionLogo {
    top: 25px;
  }
  .sizeLogo {
    max-width: 220px;
  }
}
@media (max-width: 992px) {
  .sizeLogo {
    max-width: 200px;
  }
  .headerForm {
    grid-gap: 10px;
  }
}
@media (max-width: 768px) {
  .plug {
    min-height: 56px;
  }
  .headerRoot {
    min-height: 56px;
  }
  .headerRoot.scrollTop .positionLogo {
    top: 50%;
  }
  .headerRoot.mobile .sizeLogo {
    max-width: 101px;
  }
  .headerRoot.mobile:not(.landingHeader) .sizeLogo {
    max-width: 101px;
  }
}

@media print {
  .plug,
  .headerRoot {
    display: none;
  }
}
