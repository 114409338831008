.wrapper {
  padding: 50px 0;
}
.header {
  margin-bottom: 50px;
}
.title {
  font-weight: 600;
  font-size: 44px;
}
.content,
.forms {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
}
.list:not(:empty) {
  border-radius: 14px;
  border: 1px solid var(--color-border-table-medical-reports);
}
.listItem:not(:last-child) {
  border-bottom: 1px solid var(--color-border-table-medical-reports);
}
.listItem {
  transition: box-shadow 0.2s;
  cursor: pointer;
}
.listItem:hover {
  box-shadow: var(--box-shadow-primary);
}
.labHeader {
  background-color: var(--color-text-primary);
  color: var(--color-text-accent);
  font-weight: 600;
  border-radius: 14px;
  padding: 20px 48px;
  display: grid;
  grid-template-columns: 230px 80px 50px 50px 1fr 1fr 80px;
  grid-gap: 20px;
  align-items: center;
}

/* print view */
.printView {
  display: none;
}
.printHeader {
  text-align: center;
}
.printTitle {
  font-weight: 600;
  font-size: 44px;
}
.printDate {
  font-weight: 600;
  float: right;
}
.printLabel {
  padding-top: 20px;
  font-size: 20px;
}
.printView p {
  white-space: pre-line;
}

@media (max-width: 992px) {
  .labHeader {
    display: none;
  }
  .list:not(:empty) {
    border-radius: 0;
  }
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 30px;
  }
  .title {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .header {
    margin-bottom: 20px;
  }
  .title {
    font-size: 28px;
  }
  .list:not(:empty) {
    margin: 0 -16px;
  }
}

@media print {
  .wrapper {
    padding: 0;
  }
  .header,
  .forms {
    display: none;
    grid-gap: 0;
  }
  .content {
    padding-top: 20px;
    grid-gap: 20px;
  }
  .labHeader {
    border: 1px solid black;
    color: black;
  }
  .printView {
    display: block;
  }
}
